import axios from 'axios'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import FormData from 'form-data'
import {
  apiRootUrl,
  apiXauthToken
} from './config.js'
import {
  HTTP_GET_METHOD,
  HTTP_POST_METHOD,
  HTTP_PUT_METHOD,
  HTTP_DELETE_METHOD
} from '../constants.js'

// CONFIG

const API_ROOT_URL = apiRootUrl
const API_XAUTH_TOKEN = apiXauthToken

// PRIVATE METHODS

const handleError = async (error) => {
  if (error.message.toLowerCase().indexOf('token') > -1 || error.response.status === 401) {
    const res = await refreshApiToken()
    if (!res || res instanceof Error || !res.access_token) {
      toast.error('Unable to refresh API token, please logout and login again.')
    } else {
      Cookies.set('api-token', res.access_token)
      window.location.href = '/'
    }
  } else {
    return error
  }
}

const send = async (method, route, body, params) => {
  if (!method) throw new Error("Cannot invoke send() method in api.js without a valid 'method' arg!")
  if (!route) throw new Error("Cannot invoke send() method in api.js without a valid 'route' arg!")

  const fullUrl = API_ROOT_URL + route
  const apiToken = Cookies.get('api-token')
  let headers
  if (!apiToken) {
    headers = {
      'X-Authorization': API_XAUTH_TOKEN
    }
  } else {
    headers = {
      'X-Authorization': API_XAUTH_TOKEN,
      Authorization: `Bearer ${apiToken}`
    }
  }

  switch (method) {
    case HTTP_GET_METHOD:
      return axios.get(fullUrl, body, { params, headers }).catch(error => handleError(error))
    case HTTP_POST_METHOD:
      return axios.post(fullUrl, body, { params, headers }).catch(error => handleError(error))
    case HTTP_PUT_METHOD:
      return axios.put(fullUrl, body, { params, headers }).catch(error => handleError(error))
    case HTTP_DELETE_METHOD:
      return axios.delete(fullUrl, body, { params, headers }).catch(error => handleError(error))
    default:
      return new Error('Invalid method received at send() in api.js!')
  }
}

// PUBLIC METHODS

export const getApiToken = async (email, password) => {
  const res = await send(HTTP_POST_METHOD, '/auth/login', null, {
    email,
    password
  })
    .catch(error => { return new Error(error.message) })
  if (!res || res instanceof Error) {
    return res
  }

  return res.data
}

export const refreshApiToken = async () => {
  const res = await send(HTTP_POST_METHOD, '/auth/refresh-token')
    .catch(error => { return new Error(error.message) })
  if (!res || res instanceof Error) return res

  return res.data
}

export const getUserProfile = async () => {
  const res = await send(HTTP_GET_METHOD, '/auth/me')
    .catch(error => { return new Error(error.message) })
  if (!res || res instanceof Error) return res

  return res.data
}

export const editUserInfo = async (name, username) => {
  const res = await send(HTTP_PUT_METHOD, '/user/info', {
    name,
    username
  })
    .catch(error => { return new Error(error.message) })
  if (!res || res instanceof Error) return res

  return res.data
}

export const editUserAvatar = async (file) => {
  const formData = new FormData()
  formData.append('avatar', file)
  const res = await send(HTTP_POST_METHOD, '/user/avatar', formData)
    .catch(error => { return new Error(error.message) })
  if (!res || res instanceof Error) return res

  return res.data
}

export const editUserPassword = async (password) => {
  const res = await send(HTTP_PUT_METHOD, '/user/password', {
    password,
    confirm_password: password
  })
    .catch(error => { return new Error(error.message) })
  if (!res || res instanceof Error) return res

  return res.data
}

export const getReportData = async (startDate, endDate) => {
  const res = await send(HTTP_POST_METHOD, '/reports/data', {
    startDate,
    endDate,
    breakBy: [
      'site',
      'item',
      'date'
    ],
    sort: {
      date: 'asc'
    }
  })
    .catch(error => { return new Error(error.message) })
  if (!res || res instanceof Error) return res

  return res.data
}

export const getWorldMapReports = async (startDate, endDate) => {
  const res = await send(HTTP_POST_METHOD, '/reports/data', {
    startDate,
    endDate,
    breakBy: ['country']
  })
    .catch(error => { return new Error(error.message) })
  if (!res || res instanceof Error) return res

  return res.data
}
