import React from 'react'
import {
  Row,
  Col
} from 'react-bootstrap'
import PageUnderConstructionPlaceholderComponent from '../components/PageUnderConstructionPlaceholderComponent'

const PasswordResetView = () => {
  return (
    <Row>
      <Col>
        <PageUnderConstructionPlaceholderComponent />
      </Col>
      <Col />
    </Row>
  )
}

export default PasswordResetView
