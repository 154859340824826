import React, { useState } from 'react'
import {
  Row,
  Col,
  Form
} from 'react-bootstrap'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import ButtonComponent from '../components/ButtonComponent'
import FileDropzoneComponent from '../components/FileDropzoneComponent'
import {
  editUserAvatar,
  editUserInfo,
  editUserPassword
} from '../api/api'

const UserProfileView = () => {
  const [name, setName] = useState(Cookies.get('user-name') || '')
  const [email, setEmail] = useState(Cookies.get('user-email') || '')
  const [username, setUsername] = useState(Cookies.get('user-username') || '')
  const [password, setPassword] = useState('')
  const [confirmedPassword, setConfirmedPassword] = useState('')
  const [newAvatar, setNewAvatar] = useState()
  const [currentAvatar, setCurrentAvatar] = useState(Cookies.get('user-avatar') || '')

  const onDrop = (acceptedFiles) => {
    setNewAvatar(acceptedFiles)
    const fr = new window.FileReader()
    fr.onload = function () { document.getElementById('current-avatar').src = fr.result }
    fr.readAsDataURL(acceptedFiles[0])
  }

  const saveDetails = () => {
    if (!name) {
      toast.error('Please enter a valid Name, and try again.')
      return
    }
    if (!username) {
      toast.error('Please enter a valid Username, and try again.')
      return
    }
    editUserInfo(name, username)
      .then(res => {
        if (!res || !res.status) {
          toast.error('An error occured while trying to save user details. Please check values are valid and try again.')
          return
        }
        toast.success('User details successfully updated!')
        Cookies.set('user-name', name)
        Cookies.set('user-username', username)
      })
      .catch(() => {
        toast.error('An error occured while trying to save user details. Please check values are valid and try again.')
      })
  }

  const savePassword = () => {
    if (!password) {
      toast.error('Please enter a valid Password, and try again.')
      return
    }
    if (!confirmedPassword) {
      toast.error('Please confirm Password, and try again.')
      return
    }
    if (password !== confirmedPassword) {
      toast.error('Passwords do not mach. Please ensure passwords match before attempting to save.')
    } else {
      editUserPassword(password)
        .then(res => {
          if (!res || !res.status) {
            toast.error('An error occured while trying to save new password. Please check values are valid and try again.')
            return
          }
          toast.success('User password successfully updated!')
        })
        .catch(() => {
          toast.error('An error occured while trying to save new password. Please check values are valid and try again.')
        })
    }
  }

  const saveNewAvatar = () => {
    if (!newAvatar || !newAvatar.length) {
      toast.error('Selected image is not valid or missing. Please check your input and try again.')
    } else {
      editUserAvatar(newAvatar[0])
        .then(res => {
          if (!res || !res.status || !res.user || !res.user.avatar) {
            toast.error('An error occured while trying to save new avatar. Please check image is valid and try again.')
            return
          }
          toast.success('User avatar successfully updated!')
          setNewAvatar(null)
          setCurrentAvatar(res.user.avatar)
          Cookies.set('user-avatar', res.user.avatar)
        })
        .catch(() => {
          toast.error('An error occured while trying to save new avatar. Please check image is valid and try again.')
        })
    }
  }

  const avatarStyle = {
    borderRadius: '50%'
  }

  return (
    <Row>
      <Col
        xl='6'
        lg='12'
      >
        <Form style={{ width: '80%' }}>
          <Form.Group
            className='mb-3'
            controlId='user-name'
          >
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter new name'
              value={name}
              onChange={(e) => { setName(e.target.value) }}
            />
          </Form.Group>
          <Form.Group
            className='mb-3'
            controlId='user-email'
          >
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter new email'
              value={email}
              onChange={(e) => { setEmail(e.target.value) }}
              disabled
            />
            <Form.Text className='text-muted'>
              &ensp;We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
          <Form.Group
            className='mb-3'
            controlId='user-username'
          >
            <Form.Label>Username</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter new username'
              value={username}
              onChange={(e) => { setUsername(e.target.value) }}
            />
          </Form.Group>
          <br />
          <Row>
            <Col />
            <Col xs='auto'>
              <ButtonComponent
                text='Save Details'
                iconClass={(!name || !username) ? 'bi-three-dots' : 'bi-hdd'}
                onClick={() => { saveDetails() }}
                disabled={!name || !username}
              />
            </Col>
          </Row>
        </Form>
      </Col>
      <Col
        xl='6'
        lg='12'
      >
        <Form style={{ width: '80%' }}>
          <Form.Group
            className='mb-3'
            controlId='user-password'
          >
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Enter new password'
              value={password}
              onChange={(e) => { setPassword(e.target.value) }}
            />
          </Form.Group>
          <Form.Group
            className='mb-3'
            controlId='user-confirm-password'
          >
            <Form.Label>Confirm New Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Enter password again'
              value={confirmedPassword}
              onChange={(e) => { setConfirmedPassword(e.target.value) }}
            />
          </Form.Group>
          <br />
          <Row>
            <Col />
            <Col xs='auto'>
              <ButtonComponent
                text='Save Password'
                iconClass={(!password || !confirmedPassword) ? 'bi-three-dots' : 'bi-hdd'}
                onClick={() => { savePassword() }}
                disabled={!password || !confirmedPassword}
              />
            </Col>
          </Row>
        </Form>
        <br />
        <br />
        <Form style={{ width: '80%', display: 'none' }}>
          <Form.Group
            className='mb-3'
            controlId='user-avatar'
            style={{
              borderRadius: '4px',
              border: '1px dashed rgb(255, 255, 255, 0.3)'
            }}
          >
            <div style={{ padding: '12px 12px' }}>
              <Form.Label>{newAvatar ? 'New Avatar' : 'Current Avatar'}</Form.Label>
              <br />
              {newAvatar
                ? (
                  <Row>
                    <Col />
                    <Col>
                      <img
                        id='current-avatar'
                        alt='New Avatar'
                        style={avatarStyle}
                      />
                    </Col>
                    <Col />
                  </Row>
                  )
                : currentAvatar
                  ? (
                    <Row>
                      <Col />
                      <Col>
                        <img
                          id='current-avatar'
                          alt='Current Avatar'
                          src={currentAvatar}
                          style={avatarStyle}
                        />
                      </Col>
                      <Col />
                    </Row>
                    )
                  : (
                    <p style={{ fontSize: '0.75rem' }}>No avatar currently set.</p>
                    )}
              {!newAvatar
                ? (
                  <>
                    <br />
                    <FileDropzoneComponent onDrop={onDrop} />
                  </>
                  )
                : null}
            </div>
          </Form.Group>
          <br />
          <Row>
            <Col />
            <Col xs='auto'>
              <ButtonComponent
                text='Save Avatar'
                iconClass='bi-three-dots'
                onClick={() => { saveNewAvatar() }}
                disabled={!newAvatar}
              />
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  )
}

export default UserProfileView
