import React from 'react'
import {
  Routes,
  Route
} from 'react-router-dom'
import LoginView from './views/LoginView'
import PasswordResetView from './views/PasswordResetView'
import {
  LOGIN_VIEW_URL,
  PASSWORD_RESET_VIEW_URL
} from './constants.js'
import RedirectView from './views/RedirectView'

const LoginRoot = () => {
  return (
    <div>
      <Routes>
        <Route
          exact
          path={LOGIN_VIEW_URL}
          element={<LoginView />}
        />
        <Route
          exact
          path={PASSWORD_RESET_VIEW_URL}
          element={<PasswordResetView />}
        />
        <Route
          path='*'
          element={<RedirectView url={LOGIN_VIEW_URL} />}
        />
      </Routes>
    </div>
  )
}

export default LoginRoot
