import React from 'react'
import { Card } from 'react-bootstrap'

const CardComponent = (props) => {
  return (
    <Card style={{ marginBottom: props.marginBottom || '1rem' }}>
      <Card.Body>
        {props.children}
      </Card.Body>
      <div className='card-arrow'>
        <div className='card-arrow-top-left' />
        <div className='card-arrow-top-right' />
        <div className='card-arrow-bottom-left' />
        <div className='card-arrow-bottom-right' />
      </div>
    </Card>
  )
}

export default CardComponent
