import React from 'react'
import {
  Routes,
  Route
} from 'react-router-dom'
import DashboardView from './views/DashboardView'
import ReportingView from './views/ReportingView'
import UserInvoicesView from './views/UserInvoicesView'
import UserProfileView from './views/UserProfileView'
import RedirectView from './views/RedirectView'
import {
  DASHBOARD_VIEW_URL,
  REPORTING_VIEW_URL,
  INVOICES_VIEW_URL,
  PROFILE_VIEW_URL
} from './constants.js'

const ContentRoot = () => {
  return (
    <div
      id='content'
      className='app-content'
    >
      <Routes>
        <Route
          exact
          path={DASHBOARD_VIEW_URL}
          element={<DashboardView />}
        />
        <Route
          exact
          path={REPORTING_VIEW_URL}
          element={<ReportingView />}
        />
        <Route
          exact
          path={INVOICES_VIEW_URL}
          element={<UserInvoicesView />}
        />
        <Route
          exact
          path={PROFILE_VIEW_URL}
          element={<UserProfileView />}
        />
        <Route
          path='*'
          element={<RedirectView url={DASHBOARD_VIEW_URL} />}
        />
      </Routes>
    </div>
  )
}

export default ContentRoot
