import React from 'react'

const PageUnderConstructionPlaceholderComponent = () => {
  return (
    <>
      <h1 style={{ marginTop: '1rem' }}>
        <i
          className='bi bi-cone-striped'
          style={{ color: 'orange' }}
        />
        &ensp;Page Under Construction&ensp;
        <i
          className='bi bi-cone-striped'
          style={{ color: 'orange' }}
        />
      </h1>
      <h2 style={{ marginTop: '0.25rem', marginLeft: '1rem' }}>Please check back later...</h2>
    </>
  )
}

export default PageUnderConstructionPlaceholderComponent
