// INTERNAL URLS
export const LOGIN_VIEW_URL = '/login'
export const PASSWORD_RESET_VIEW_URL = '/password-reset'
export const DASHBOARD_VIEW_URL = '/dashboard'
export const REPORTING_VIEW_URL = '/reporting'
export const INVOICES_VIEW_URL = '/user-invoices'
export const PROFILE_VIEW_URL = '/user-profile'

// EXTERNAL URLS
export const GSM_HOMEPAGE_URL = 'https://www.globalsun.io/'

// APEX CHARTS
export const APEX_CHART_TYPE_LINE = 'line'
export const APEX_CHART_TYPE_AREA = 'area'
export const APEX_CHART_TYPE_BAR = 'bar'
export const APEX_CHART_TYPE_PIE = 'pie'
export const APEX_CHART_TYPE_DONUT = 'donut'

// HTTP
export const HTTP_GET_METHOD = 'GET'
export const HTTP_POST_METHOD = 'POST'
export const HTTP_PUT_METHOD = 'PUT'
export const HTTP_DELETE_METHOD = 'DELETE'

// COLORS
export const THEME_COLOR_PRIMARY = '#f9e80d'
export const THEME_COLOR_PRIMARY_RGB = '249, 232, 13'
export const THEME_COLOR_SECONDARY = '#f38812'
export const THEME_COLOR_SECONDARY_RGB = '248, 177, 14'
export const THEME_COLOR_BACKGROUND = '#1d2835'
export const THEME_COLOR_BACKGROUND_RGB = '29, 40, 53'
export const THEME_COLOR_LIGHT = '#fdf8b0'
export const THEME_COLOR_LIGHT_RGB = '252, 243, 141'
export const THEME_COLOR_TEXT = '#a0a0a0'
export const THEME_COLOR_TEXT_RGB = '160, 160, 160'

// TEXT STRINGS
export const TEXT_LOADING = 'Loading...'

// MISC STYLES /
export const SELECT_STYLE = {
  option: (provided) => ({
    ...provided,
    backgroundColor: THEME_COLOR_BACKGROUND,
    color: '#fff',
    borderColor: THEME_COLOR_TEXT
  }),
  control: (provided) => ({
    ...provided,
    height: 30,
    maxWidth: 400,
    minWidth: 280,
    backgroundColor: THEME_COLOR_BACKGROUND,
    color: '#fff',
    borderColor: THEME_COLOR_TEXT
  }),
  singleValue: (provided) => ({
    ...provided,
    backgroundColor: THEME_COLOR_BACKGROUND,
    color: '#fff',
    borderColor: THEME_COLOR_TEXT
  })
}

// METRICS
export const METRIC_REVENUE = 'Revenue'
export const METRIC_INVENTORY = 'Inventory'
export const METRIC_IMPRESSION = 'Impression'
export const METRIC_FILLRATE = 'Fill Rate'
export const METRIC_CPM = 'CPM'
export const METRIC_COMPLETION = 'Completion'

// COUNTRY NAMES MAPPED TO CODES
export const COUNTRY_TO_CODES = [
  { name: 'Afghanistan', iso2: 'AF' },
  { name: 'Aland Islands', iso2: 'AX' },
  { name: 'Albania', iso2: 'AL' },
  { name: 'Algeria', iso2: 'DZ' },
  { name: 'American Samoa', iso2: 'AS' },
  { name: 'Andorra', iso2: 'AD' },
  { name: 'Angola', iso2: 'AO' },
  { name: 'Anguilla', iso2: 'AI' },
  { name: 'Antarctica', iso2: 'AQ' },
  { name: 'Antigua And Barbuda', iso2: 'AG' },
  { name: 'Argentina', iso2: 'AR' },
  { name: 'Armenia', iso2: 'AM' },
  { name: 'Aruba', iso2: 'AW' },
  { name: 'Australia', iso2: 'AU' },
  { name: 'Austria', iso2: 'AT' },
  { name: 'Azerbaijan', iso2: 'AZ' },
  { name: 'Bahamas', iso2: 'BS' },
  { name: 'Bahrain', iso2: 'BH' },
  { name: 'Bangladesh', iso2: 'BD' },
  { name: 'Barbados', iso2: 'BB' },
  { name: 'Belarus', iso2: 'BY' },
  { name: 'Belgium', iso2: 'BE' },
  { name: 'Belize', iso2: 'BZ' },
  { name: 'Benin', iso2: 'BJ' },
  { name: 'Bermuda', iso2: 'BM' },
  { name: 'Bhutan', iso2: 'BT' },
  { name: 'Bolivia', iso2: 'BO' },
  { name: 'Bonaire', iso2: 'BQ' },
  { name: 'Bonaire, Saint Eustatius and Saba', iso2: 'BQ' },
  { name: 'Bosnia and Herzegovina', iso2: 'BA' },
  { name: 'Botswana', iso2: 'BW' },
  { name: 'Brazil', iso2: 'BR' },
  { name: 'British Indian Ocean Territory', iso2: 'IO' },
  { name: 'Brunei', iso2: 'BN' },
  { name: 'Bulgaria', iso2: 'BG' },
  { name: 'Burkina Faso', iso2: 'BF' },
  { name: 'Burundi', iso2: 'BI' },
  { name: 'Cambodia', iso2: 'KH' },
  { name: 'Cameroon', iso2: 'CM' },
  { name: 'Canada', iso2: 'CA' },
  { name: 'Cape Verde', iso2: 'CV' },
  { name: 'Cabo Verde', iso2: 'CV' },
  { name: 'Cayman Islands', iso2: 'KY' },
  { name: 'Central African Republic', iso2: 'CF' },
  { name: 'Chad', iso2: 'TD' },
  { name: 'Chile', iso2: 'CL' },
  { name: 'China', iso2: 'CN' },
  { name: 'Colombia', iso2: 'CO' },
  { name: 'Cocos Islands', iso2: 'CC' },
  { name: 'Comoros', iso2: 'KM' },
  { name: 'Republic Of The Congo', iso2: 'CG' },
  { name: 'Congo Republic', iso2: 'CG' },
  { name: 'Democratic Republic of the Congo', iso2: 'CD' },
  { name: 'DR Congo', iso2: 'CD' },
  { name: 'Cook Islands', iso2: 'CK' },
  { name: 'Costa Rica', iso2: 'CR' },
  { name: 'Croatia', iso2: 'HR' },
  { name: 'Cuba', iso2: 'CU' },
  { name: 'Curacao', iso2: 'CW' },
  { name: 'Cyprus', iso2: 'CY' },
  { name: 'Czechia', iso2: 'CZ' },
  { name: 'Czech Republic', iso2: 'CZ' },
  { name: 'Denmark', iso2: 'DK' },
  { name: 'Djibouti', iso2: 'DJ' },
  { name: 'Dominica', iso2: 'DM' },
  { name: 'Dominican Republic', iso2: 'DO' },
  { name: 'Timor Leste', iso2: 'TL' },
  { name: 'Timor-Leste', iso2: 'TL' },
  { name: 'East Timor', iso2: 'TL' },
  { name: 'Ecuador', iso2: 'EC' },
  { name: 'Egypt', iso2: 'EG' },
  { name: 'El Salvador', iso2: 'SV' },
  { name: 'Equatorial Guinea', iso2: 'GQ' },
  { name: 'Eritrea', iso2: 'ER' },
  { name: 'Estonia', iso2: 'EE' },
  { name: 'Ethiopia', iso2: 'ET' },
  { name: 'Eswatini', iso2: 'SZ' },
  { name: 'Swaziland', iso2: 'SZ' },
  { name: 'Falkland Islands', iso2: 'FK' },
  { name: 'Faroe Islands', iso2: 'FO' },
  { name: 'Micronesia, Federated States Of', iso2: 'FM' },
  { name: 'Federated States Of Micronesia', iso2: 'FM' },
  { name: 'Micronesia', iso2: 'FM' },
  { name: 'Fiji', iso2: 'FJ' },
  { name: 'Finland', iso2: 'FI' },
  { name: 'France', iso2: 'FR' },
  { name: 'French Polynesia', iso2: 'PF' },
  { name: 'French Guiana', iso2: 'GF' },
  { name: 'French Southern Territories', iso2: 'TF' },
  { name: 'Gabon', iso2: 'GA' },
  { name: 'Georgia', iso2: 'GE' },
  { name: 'Germany', iso2: 'DE' },
  { name: 'Ghana', iso2: 'GH' },
  { name: 'Gibraltar', iso2: 'GI' },
  { name: 'Greece', iso2: 'GR' },
  { name: 'Greenland', iso2: 'GL' },
  { name: 'Grenada', iso2: 'GD' },
  { name: 'Guadeloupe', iso2: 'GP' },
  { name: 'Guam', iso2: 'GU' },
  { name: 'Guernsey', iso2: 'GG' },
  { name: 'Guatemala', iso2: 'GT' },
  { name: 'Guinea', iso2: 'GN' },
  { name: 'Guinea Bissau', iso2: 'GW' },
  { name: 'Guinea-Bissau', iso2: 'GW' },
  { name: 'Guyana', iso2: 'GY' },
  { name: 'Haiti', iso2: 'HT' },
  { name: 'Honduras', iso2: 'HN' },
  { name: 'Hong Kong', iso2: 'HK' },
  { name: 'Hungary', iso2: 'HU' },
  { name: 'Iceland', iso2: 'IS' },
  { name: 'India', iso2: 'IN' },
  { name: 'Indonesia', iso2: 'ID' },
  { name: 'Iran', iso2: 'IR' },
  { name: 'Iraq', iso2: 'IQ' },
  { name: 'Ireland', iso2: 'IE' },
  { name: 'Isle Of Man', iso2: 'IM' },
  { name: 'Israel', iso2: 'IL' },
  { name: 'Italy', iso2: 'IT' },
  { name: 'Ivory Coast', iso2: 'CI' },
  { name: "Côte d'Ivoire", iso2: 'CI' },
  { name: 'Jamaica', iso2: 'JM' },
  { name: 'Japan', iso2: 'JP' },
  { name: 'Jersey', iso2: 'JE' },
  { name: 'Jordan', iso2: 'JO' },
  { name: 'Kazakhstan', iso2: 'KZ' },
  { name: 'Kenya', iso2: 'KE' },
  { name: 'Kosovo', iso2: 'RS' },
  { name: 'Kiribati', iso2: 'KI' },
  { name: 'Kuwait', iso2: 'KR' },
  { name: 'Kyrgyzstan', iso2: 'KG' },
  { name: 'Laos', iso2: 'LA' },
  { name: 'Latvia', iso2: 'LV' },
  { name: 'Lebanon', iso2: 'LB' },
  { name: 'Lesotho', iso2: 'LS' },
  { name: 'Liberia', iso2: 'LR' },
  { name: 'Libya', iso2: 'LY' },
  { name: 'Liechtenstein', iso2: 'LI' },
  { name: 'Lithuania', iso2: 'LT' },
  { name: 'Luxembourg', iso2: 'LU' },
  { name: 'Macao', iso2: 'MO' },
  { name: 'North Macedonia', iso2: 'MK' },
  { name: 'Macedonia', iso2: 'MK' },
  { name: 'Norfolk Island', iso2: 'NF' },
  { name: 'Madagascar', iso2: 'MG' },
  { name: 'Malawi', iso2: 'MW' },
  { name: 'Malaysia', iso2: 'MY' },
  { name: 'Maldives', iso2: 'MV' },
  { name: 'Mali', iso2: 'ML' },
  { name: 'Malta', iso2: 'MT' },
  { name: 'Mayotte', iso2: 'YT' },
  { name: 'Marshall Islands', iso2: 'MH' },
  { name: 'Martinique', iso2: 'MQ' },
  { name: 'Mauritania', iso2: 'MR' },
  { name: 'Mauritius', iso2: 'MU' },
  { name: 'Mexico', iso2: 'MX' },
  { name: 'Moldova', iso2: 'MD' },
  { name: 'Monaco', iso2: 'MC' },
  { name: 'Mongolia', iso2: 'MN' },
  { name: 'Montenegro', iso2: 'ME' },
  { name: 'Montserrat', iso2: 'MS' },
  { name: 'Morocco', iso2: 'MA' },
  { name: 'Mozambique', iso2: 'MZ' },
  { name: 'Myanmar', iso2: 'MM' },
  { name: 'Namibia', iso2: 'NA' },
  { name: 'Nauru', iso2: 'NR' },
  { name: 'Nepal', iso2: 'NP' },
  { name: 'Netherlands', iso2: 'NL' },
  { name: 'New Caledonia', iso2: 'NC' },
  { name: 'New Zealand', iso2: 'NZ' },
  { name: 'Nicaragua', iso2: 'NI' },
  { name: 'Niger', iso2: 'NE' },
  { name: 'Nigeria', iso2: 'NG' },
  { name: 'North Korea', iso2: 'KP' },
  { name: 'Northern Mariana Islands', iso2: 'MP' },
  { name: 'Norway', iso2: 'NO' },
  { name: 'Oman', iso2: 'OM' },
  { name: 'Pakistan', iso2: 'PK' },
  { name: 'Palau', iso2: 'PW' },
  { name: 'Palestine', iso2: 'PS' },
  { name: 'Palestinian Territory', iso2: 'PS' },
  { name: 'Panama', iso2: 'PA' },
  { name: 'Papua New Guinea', iso2: 'PG' },
  { name: 'Paraguay', iso2: 'PY' },
  { name: 'Peru', iso2: 'PE' },
  { name: 'Philippines', iso2: 'PH' },
  { name: 'Pitcairn', iso2: 'PN' },
  { name: 'Poland', iso2: 'PL' },
  { name: 'Portugal', iso2: 'PT' },
  { name: 'Puerto Rico', iso2: 'PR' },
  { name: 'Qatar', iso2: 'QA' },
  { name: 'Romania', iso2: 'RO' },
  { name: 'Reunion', iso2: 'RE' },
  { name: 'Russia', iso2: 'RU' },
  { name: 'Rwanda', iso2: 'RW' },
  { name: 'St Kitts And Nevis', iso2: 'KN' },
  { name: 'Saint Kitts And Nevis', iso2: 'KN' },
  { name: 'Saint Barthelemy', iso2: 'BL' },
  { name: 'Saint Lucia', iso2: 'LC' },
  { name: 'Saint Martin', iso2: 'MF' },
  { name: 'Sint Maarten', iso2: 'MF' },
  { name: 'Saint Vincent And The Grenadines', iso2: 'VC' },
  { name: 'St Vincent And The Grenadines', iso2: 'VC' },
  { name: 'St Vincent And Grenadines', iso2: 'VC' },
  { name: 'Saint Pierre And Miquelon', iso2: 'PM' },
  { name: 'Samoa', iso2: 'WS' },
  { name: 'San Marino', iso2: 'SM' },
  { name: 'Sao Tome And Principe', iso2: 'ST' },
  { name: 'São Tomé and Príncipe', iso2: 'ST' },
  { name: 'Saudi Arabia', iso2: 'SA' },
  { name: 'San Marino', iso2: 'SM' },
  { name: 'Serbia', iso2: 'RS' },
  { name: 'Senegal', iso2: 'SN' },
  { name: 'Seychelles', iso2: 'SC' },
  { name: 'Sierra Leone', iso2: 'SL' },
  { name: 'Singapore', iso2: 'SG' },
  { name: 'Slovakia', iso2: 'SK' },
  { name: 'Slovenia', iso2: 'SI' },
  { name: 'Solomon Islands', iso2: 'SB' },
  { name: 'Somalia', iso2: 'SO' },
  { name: 'South Africa', iso2: 'ZA' },
  { name: 'South Georgia And South Sandwich Islands', iso2: 'GS' },
  { name: 'South Korea', iso2: 'KR' },
  { name: 'Spain', iso2: 'ES' },
  { name: 'Sri Lanka', iso2: 'LK' },
  { name: 'Sudan', iso2: 'SD' },
  { name: 'South Sudan', iso2: 'SS' },
  { name: 'Suriname', iso2: 'SR' },
  { name: 'Svalbard', iso2: 'SJ' },
  { name: 'Svalbard and Jan Mayen', iso2: 'SJ' },
  { name: 'Sweden', iso2: 'SE' },
  { name: 'Switzerland', iso2: 'CH' },
  { name: 'Syria', iso2: 'SY' },
  { name: 'Taiwan', iso2: 'TW' },
  { name: 'Tajikistan', iso2: 'TJ' },
  { name: 'Tanzania', iso2: 'TZ' },
  { name: 'Thailand', iso2: 'TH' },
  { name: 'Gambia', iso2: 'GM' },
  { name: 'Togo', iso2: 'TG' },
  { name: 'Tonga', iso2: 'TO' },
  { name: 'Trinidad And Tobago', iso2: 'TT' },
  { name: 'Tunisia', iso2: 'TN' },
  { name: 'Turkey', iso2: 'TR' },
  { name: 'Turkmenistan', iso2: 'TM' },
  { name: 'Turks And Caicos Islands', iso2: 'TC' },
  { name: 'Tuvalu', iso2: 'TV' },
  { name: 'Uganda', iso2: 'UG' },
  { name: 'Ukraine', iso2: 'UA' },
  { name: 'United Arab Emirates', iso2: 'AE' },
  { name: 'United Kingdom', iso2: 'GB' },
  { name: 'United Kingdom of Great Britain and Northern Ireland', iso2: 'GB' },
  { name: 'United States', iso2: 'US' },
  { name: 'United States of America', iso2: 'US' },
  { name: 'British Virgin Islands', iso2: 'VG' },
  { name: 'US Virgin Islands', iso2: 'VI' },
  { name: 'U.S. Virgin Islands', iso2: 'VI' },
  { name: 'Uruguay', iso2: 'UY' },
  { name: 'Uzbekistan', iso2: 'UZ' },
  { name: 'Vanuatu', iso2: 'VU' },
  { name: 'Holy See', iso2: 'VA' },
  { name: 'Vatican', iso2: 'VA' },
  { name: 'Venezuela', iso2: 'VE' },
  { name: 'Vietnam', iso2: 'VN' },
  { name: 'Wallis and Futuna', iso2: 'WF' },
  { name: 'Western Sahara', iso2: 'EH' },
  { name: 'Yemen', iso2: 'YE' },
  { name: 'Zambia', iso2: 'ZM' },
  { name: 'Zimbabwe', iso2: 'ZW' }
]
