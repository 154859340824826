import React, { useRef } from 'react'
import Chart from 'react-apexcharts'
import {
  METRIC_CPM,
  METRIC_FILLRATE,
  METRIC_REVENUE,
  THEME_COLOR_PRIMARY,
  THEME_COLOR_SECONDARY
} from '../constants'
import getColorsByChartType from '../utils/getColorsByChartType'
import getStrokeByChartType from '../utils/getStrokeByChartType'
import getPlotOptionsByChartType from '../utils/getPlotOptionsByChartType'
import CardComponent from './CardComponent'
import getYAxisFormatter from '../utils/getYAxisFormatter'

const ChartCardComponent = (props) => {
  const chartRef = useRef(null)

  return (
    <CardComponent>
      <>
        <div className='d-flex fw-bold small mb-3'>
          <span
            className='flex-grow-1'
            style={{ fontSize: '0.9rem' }}
          >
            {props.titleText}
          </span>
          {props && props.onClickExpand && typeof props.onClickExpand === 'function'
            ? (
              <a
                href='#'
                className='text-white text-opacity-50 text-decoration-none'
                onClick={(e) => {
                  e.preventDefault()
                  props.onClickExpand(chartRef, props.titleText)
                }}
              >
                <i
                  className='bi bi-fullscreen'
                  id={props.id}
                />
              </a>
              )
            : null}
        </div>
        <div style={{ width: '100%' }}>
          <Chart
            ref={chartRef}
            type={props.chartType}
            options={{
              chart: {
                toolbar: {
                  show: false
                },
                sparkline: {
                  enabled: false
                }
              },
              dataLabels: {
                enabled: false
              },
              colors: props.twoLines
                ? [THEME_COLOR_PRIMARY, '#f7b10f']
                : getColorsByChartType(props.chartType),
              stroke: getStrokeByChartType(props.chartType),
              markers: {
                shape: 'circle',
                colors: '#2a343f',
                size: 5,
                strokeColors: props.twoLines ? [THEME_COLOR_PRIMARY, `${THEME_COLOR_SECONDARY}`] : THEME_COLOR_PRIMARY,
                strokeWidth: 2
              },
              plotOptions: getPlotOptionsByChartType(props.chartType),
              xaxis: {
                categories: props.categories,
                labels: {
                  show: true
                }
              },
              yaxis: {
                labels: {
                  show: true,
                  formatter: getYAxisFormatter(props.titleText)
                }
              },
              grid: {
                show: false
              },
              tooltip: {
                theme: 'dark',
                x: {
                  show: false
                },
                y: {
                  title: {
                    formatter: function (seriesName) {
                      return ''
                    }
                  },
                  formatter: (value) => {
                    if (props.titleText === METRIC_REVENUE || props.titleText === METRIC_CPM) return '$' + value.toLocaleString()
                    if (props.titleText === METRIC_FILLRATE) return value + '%'
                    else return value.toLocaleString()
                  }
                }
              }
            }}
            series={props.series}
          />
        </div>
      </>
    </CardComponent>
  )
}

export default ChartCardComponent
