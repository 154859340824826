import React, {
  useEffect,
  useState
} from 'react'
import {
  Row,
  Col,
  Accordion
} from 'react-bootstrap'
import Select from 'react-select'
import millify from 'millify'
import date from 'date-and-time'
import addMinutes from 'date-fns/addMinutes'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import ProgressBar from '@ramonak/react-progress-bar'
import { ErrorBoundary } from 'react-error-boundary'
import Chart from 'react-apexcharts'
import { toast } from 'react-toastify'
import MetricsCardRowComponent from '../components/MetricsCardRowComponent'
import ChartCardRowComponent from '../components/ChartCardRowComponent'
import ModalComponent from '../components/ModalComponent'
import WorldMapComponent from '../components/WorldMapComponent'
import {
  THEME_COLOR_PRIMARY,
  THEME_COLOR_SECONDARY,
  SELECT_STYLE,
  THEME_COLOR_BACKGROUND,
  METRIC_REVENUE,
  METRIC_IMPRESSION,
  METRIC_INVENTORY,
  METRIC_CPM,
  METRIC_FILLRATE,
  METRIC_COMPLETION,
  TEXT_LOADING
} from '../constants'
import getColorsByChartType from '../utils/getColorsByChartType'
import getStrokeByChartType from '../utils/getStrokeByChartType'
import getPlotOptionsByChartType from '../utils/getPlotOptionsByChartType'
import {
  getReportData,
  getWorldMapReports
} from '../api/api'
import CardComponent from '../components/CardComponent'
import DateRangePickerComponent from '../components/DateRangePickerComponent'
import ThemedSpinnerComponent from '../components/ThemedSpinnerComponent'
import getYAxisFormatter from '../utils/getYAxisFormatter'
import Cookies from 'js-cookie'
import ExportComponent from '../components/ExportComponent'
import collapseAccordion from '../utils/collapseAccordion'
import ErrorFallbackComponent from '../components/ErrorFallbackComponent'

const onClickExpandChart = (setChartModalTitle, setChartModalBody, handleShowChartModal, chartRef, title, categories) => {
  const chartProps = chartRef.current.props
  setChartModalTitle(title)
  setChartModalBody(
    <Row>
      <Col style={{
        padding: '0px',
        margin: '0px'
      }}
      />
      <Col xs='auto'>
        <Chart
          type={chartProps.type}
          width={window.innerWidth * 0.45}
          height={(window.innerWidth * 0.45) * 0.5}
          options={{
            chart: {
              toolbar: {
                show: false
              },
              sparkline: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: false
            },
            colors: chartProps.series.length > 1
              ? [THEME_COLOR_PRIMARY, `${THEME_COLOR_SECONDARY}`]
              : getColorsByChartType(chartProps.type),
            stroke: getStrokeByChartType(chartProps.type),
            plotOptions: getPlotOptionsByChartType(chartProps.type),
            xaxis: {
              categories,
              labels: {
                show: true
              }
            },
            yaxis: {
              labels: {
                show: true,
                formatter: getYAxisFormatter(title)
              }
            },
            grid: {
              show: false
            },
            tooltip: {
              theme: 'dark',
              x: {
                show: false
              },
              y: {
                title: {
                  formatter: function (seriesName) {
                    return ''
                  }
                },
                formatter: (value) => { return '' + value }
              }
            }
          }}
          series={chartProps.series}
        />
      </Col>
      <Col style={{ padding: '0px', margin: '0px' }} />
    </Row>
  )
  handleShowChartModal()
}

const DATA_REFRESH_INTERVAL = 180000 // three mins
let dataRefreshTimer
let everySecondTimer // eslint-disable-line no-unused-vars
// const currentYear = (new Date()).getUTCFullYear()
// const currentMonth = (new Date()).getUTCMonth()
const currentDate = new Date()
const sevenDaysAgo = new Date()
sevenDaysAgo.setDate(currentDate.getDate() - 7)

const DashboardView = () => {
  const [showChartModal, setShowChartModal] = useState(false)
  const [chartModalTitle, setChartModalTitle] = useState('')
  const [chartModalBody, setChartModalBody] = useState(<div />)
  const [reports, setReports] = useState([])
  const [worldMapReports, setWorldMapReports] = useState([])
  const [fetchingReports, setFetchingReports] = useState(false)
  const [nextReportFetchTime, setNextReportFetchTime] = useState(null)
  const [selectedSite, setSelectedSite] = useState({
    label: TEXT_LOADING,
    value: ''
  })
  const [selectedAdunit, setSelectedAdunit] = useState({
    label: TEXT_LOADING,
    value: ''
  })
  const [dateSelection, setDateSelection] = useState({
    startDate: Cookies.get('dashboard-startDate')
      ? new Date(JSON.parse(Cookies.get('dashboard-startDate')))
      // : new Date(currentYear, currentMonth, 1),
      : sevenDaysAgo,
    endDate: Cookies.get('dashboard-endDate')
      ? new Date(JSON.parse(Cookies.get('dashboard-endDate')))
      : new Date(),
    key: 'selection'
  })
  const [startDate, setStartDate] = useState(Cookies.get('dashboard-startDate')
    ? new Date(JSON.parse(Cookies.get('dashboard-startDate')))
    // : new Date(currentYear, currentMonth, 1))
    : sevenDaysAgo)
  const [endDate, setEndDate] = useState(Cookies.get('dashboard-endDate')
    ? new Date(JSON.parse(Cookies.get('dashboard-endDate')))
    : new Date())
  const [nowTime, setNowTime] = useState(new Date())

  useEffect(() => {
    getReports(startDate, endDate)
  }, [startDate, endDate])

  useEffect(() => {
    dataRefreshTimer = setInterval(getReportsWithStateValues, DATA_REFRESH_INTERVAL)
    everySecondTimer = setInterval(updateNowTime, 10000)
  }, [])

  const handleShowChartModal = () => setShowChartModal(true)
  const handleCloseChartModal = () => setShowChartModal(false)

  const updateNowTime = () => {
    setNowTime(new Date())
  }

  // Gets the theoretical date range as based on the selection in the calendar
  // If reports are null for some dates then this will still work, however it won't account for null data
  // Therefore it cannot be used to calculate a true average, it is more for displaying a date range to the user
  const getDateRangeLengthInDaysFromSelection = () => {
    return differenceInCalendarDays(endDate, startDate) + 1 // zero-index to length
  }

  // Gets the actual date range as based on the reports
  // If reports are null for some dates then this won't work, however usually they should not be
  // Will calculate the true average for values
  const getDateRangeLengthInDaysFromReports = () => {
    return differenceInCalendarDays(new Date(reports[reports.length - 1].date), new Date(reports[0].date)) + 1 // zero-index to length
  }

  const calculateFillRate = (impression, inventory) => {
    if (!impression || !inventory) return 0
    return parseFloat(parseInt(impression) / parseInt(inventory)) * 100
  }

  const getReportsWithStateValues = () => {
    const cookieStartDate = Cookies.get('dashboard-startDate') ? new Date(JSON.parse(Cookies.get('dashboard-startDate'))) : null
    const cookieEndDate = Cookies.get('dashboard-endDate') ? new Date(JSON.parse(Cookies.get('dashboard-endDate'))) : null
    getReports(cookieStartDate || startDate, cookieEndDate || endDate)
  }

  const formatReports = (reports) => {
    return reports.map(r => {
      let revenue = r.revenue
      revenue = String(revenue)
      revenue = revenue.slice(0, revenue.indexOf('.') + 3)
      let fillRate = calculateFillRate(r.impression, r.inventory)
      fillRate = String(fillRate)
      fillRate = fillRate.indexOf('.') > -1 ? fillRate.slice(0, fillRate.indexOf('.') + 3) : fillRate
      return {
        ...r,
        fillRate,
        revenue
      }
    })
  }

  const getReports = (startDate, endDate) => {
    if (fetchingReports) return // TODO: cancel current request and send new instead of ignoring new requests

    clearInterval(dataRefreshTimer)
    dataRefreshTimer = setInterval(getReportsWithStateValues, DATA_REFRESH_INTERVAL)
    setFetchingReports(true)
    setReports(null)
    setWorldMapReports(null)
    setNextReportFetchTime(null)
    const loadingOption = {
      label: 'ALL',
      value: ''
    }
    setSelectedSite(loadingOption)
    setSelectedAdunit(loadingOption)

    const promises = []

    promises.push(new Promise((resolve) => {
      getReportData(date.format(startDate, 'YYYY-MM-DD'), date.format(endDate, 'YYYY-MM-DD'))
        .then(reports => {
          if (!reports || !reports.length) throw new Error('Reports were empty or null!')
          console.log('got reports', reports)
          reports = formatReports(reports)
          console.log('formatted reports', reports)
          setReports(reports)
          // setSelectedSite({
          //   label: reports[0].site_name,
          //   value: reports[0].site_id
          // })
          // setSelectedAdunit({
          //   label: reports[0].item_name,
          //   value: reports[0].item_id
          // })
          resolve()
        })
        .catch(error => {
          toast.error('Unable to fetch report data! Please check your connection and try again.')
          resolve(error)
        })
    }))

    promises.push(new Promise((resolve) => {
      getWorldMapReports(date.format(startDate, 'YYYY-MM-DD'), date.format(endDate, 'YYYY-MM-DD'))
        .then(reports => {
          reports = formatReports(reports)
          setWorldMapReports(reports)
          resolve()
        })
        .catch(error => {
          toast.error('Unable to fetch world map data! Please check your connection and try again.')
          resolve(error)
        })
    }))

    Promise.all(promises).then(() => {
      setNextReportFetchTime(addMinutes(new Date(), 3))
      setFetchingReports(false)
    })
  }

  const getSiteOptions = () => {
    const uniqueReports = []
    const sites = reports.map(r => r.site_id)
    const uniqueSites = []

    for (let i = 0; i < sites.length; i++) {
      if (uniqueSites.indexOf(sites[i]) < 0) {
        uniqueSites.push(sites[i])
        uniqueReports.push(reports[i])
      }
    }

    return uniqueReports.map(r => {
      return {
        label: r.site_name,
        value: r.site_id
      }
    })
  }

  const getAdunitOptions = () => {
    const uniqueReports = []
    const items = reports.map(r => r.item_id)
    const uniqueItems = []

    for (let i = 0; i < items.length; i++) {
      if (uniqueItems.indexOf(items[i]) < 0) {
        uniqueItems.push(items[i])
        uniqueReports.push(reports[i])
      }
    }

    // Filter by selected site ID since adunit is a subselection of site
    return uniqueReports.filter(report => report.site_id === selectedSite.value).map(r => {
      return {
        label: r.item_name,
        value: r.item_id
      }
    })
  }

  const getTotalInventory = (siteName) => {
    if (siteName) return reports.filter(r => r.site_name === siteName).map(r => r.inventory).reduce((partialSum, r) => partialSum + parseInt(r), 0)
    else return getSelectedReports().map(r => r.inventory).reduce((partialSum, r) => partialSum + parseInt(r), 0)
  }

  const getTotalImpression = (siteName) => {
    if (siteName) return reports.filter(r => r.site_name === siteName).map(r => r.impression).reduce((partialSum, r) => partialSum + parseInt(r), 0)
    else return getSelectedReports().map(r => r.impression).reduce((partialSum, r) => partialSum + parseInt(r), 0)
  }

  const getTotalRevenue = (siteName) => {
    if (siteName) return reports.filter(r => r.site_name === siteName).map(r => r.revenue).reduce((partialSum, r) => partialSum + parseFloat(r), 0)
    else return getSelectedReports().map(r => r.revenue).reduce((partialSum, r) => partialSum + parseFloat(r), 0)
  }

  const getAverageCpm = () => {
    const sum = getSelectedReports().map(r => r.ecpm).reduce((partialSum, r) => partialSum + parseFloat(r), 0)
    return getDateRangeLengthInDaysFromReports() > 0 ? sum / getDateRangeLengthInDaysFromReports() : 0
  }

  const getHighestCpm = () => {
    const sortedCpmDescending = getSelectedReports().map(r => r.ecpm).sort((a, b) => {
      return b - a
    })
    return sortedCpmDescending[0]
  }

  const getLowestCpm = () => {
    const sortedCpmAscending = getSelectedReports().map(r => r.ecpm).sort((a, b) => {
      return a - b
    })
    return sortedCpmAscending[0]
  }

  const getAverageFillRate = () => {
    const reports = getSelectedReports()
    const totalImpression = reports.map(r => r.impression).reduce((partialSum, r) => partialSum + parseFloat(r), 0)
    const totalInventory = reports.map(r => r.inventory).reduce((partialSum, r) => partialSum + parseFloat(r), 0)
    return calculateFillRate(totalImpression, totalInventory)
  }

  const getHighestFillRate = () => {
    const sortedFillRateDescending = getSelectedReports().map(r => r.fillRate).sort((a, b) => {
      return b - a
    })
    return sortedFillRateDescending[0]
  }

  const getLowestFillRate = () => {
    const sortedFillRateAscending = getSelectedReports().map(r => r.fillRate).sort((a, b) => {
      return a - b
    })
    return sortedFillRateAscending[0]
  }

  const getTotalCompletion = (siteName) => {
    if (siteName) return reports.filter(r => r.site_name === siteName).map(r => r.complete).reduce((partialSum, r) => partialSum + parseFloat(r), 0)
    else return getSelectedReports().map(r => r.complete).reduce((partialSum, r) => partialSum + parseFloat(r), 0)
  }

  const getSelectedReports = () => {
    if (!reports || !reports.length) return []
    if (!selectedSite.value) {
      // Sum all by date
      const sumByDate = reports.reduce((acc, entry) => {
        const date = entry.date
        const existingIndex = acc.findIndex(item => item.date === date)

        if (existingIndex !== -1) {
          acc[existingIndex].numcount += 1
          acc[existingIndex].impression += entry.impression
          acc[existingIndex].inventory += entry.inventory
          acc[existingIndex].complete += entry.complete
          acc[existingIndex].cost += entry.cost
          acc[existingIndex].ecpm += entry.ecpm
          acc[existingIndex].fillRate += parseFloat(entry.fillRate)
          acc[existingIndex].revenue += parseFloat(entry.revenue)
        } else {
          acc.push({
            date,
            numcount: 0,
            impression: entry.impression ? entry.impression : 0,
            inventory: entry.inventory ? entry.inventory : 0,
            complete: entry.complete ? entry.complete : 0,
            cost: entry.cost ? entry.cost : 0,
            ecpm: entry.ecpm ? entry.ecpm : 0,
            fillRate: entry.fillRate ? parseFloat(entry.fillRate) : 0,
            revenue: entry.revenue ? parseFloat(entry.revenue) : 0
          })
        }

        return acc
      }, [])

      // Find FillRate
      sumByDate.map(entry => {
        entry.fillRate = (parseFloat(entry.fillRate) / entry.numcount).toFixed(3)
      })

      return sumByDate
    }
    return reports.filter(r => r.site_id === selectedSite.value).filter(r => r.item_id === selectedAdunit.value)
  }

  const getSelectedReportCategories = () => {
    const selectedReports = getSelectedReports()
    if (selectedReports && selectedReports.length) return selectedReports.map(r => r.date).map(d => date.format(new Date(d), 'DD/MM'))
    return {}
  }

  const boldAccordianTitleStyle = {
    fontWeight: 400,
    color: THEME_COLOR_PRIMARY,
    fontSize: window.innerWidth <= 600 ? '13px' : '15px'
  }

  return (
    <>
      <ModalComponent
        show={showChartModal}
        handleClose={handleCloseChartModal}
        size='lg'
        centered
        modalTitle={chartModalTitle}
        modalBody={chartModalBody}
      />
      {nextReportFetchTime
        ? (
          <Row>
            <Col lg='10' xs='7'>
              <div style={{ paddingTop: '9px' }}>
                <ProgressBar
                  completed={(DATA_REFRESH_INTERVAL - (nextReportFetchTime.getTime() - nowTime.getTime())) / DATA_REFRESH_INTERVAL * 100}
                  customLabel=' '
                  height='5px'
                  bgColor={THEME_COLOR_PRIMARY}
                  baseBgColor={THEME_COLOR_BACKGROUND}
                />
              </div>
            </Col>
            <Col lg='2' xs='5'>
              <p style={{ marginBottom: '10px', fontSize: window.innerWidth <= 600 ? '12px' : '15px' }}>
                {fetchingReports
                  ? 'Refreshing data...'
                  : `Data will refresh in ${parseInt((nextReportFetchTime.getTime() - nowTime.getTime()) / 1000)}s`}
              </p>
            </Col>
          </Row>
          )
        : null}
      <Accordion>
        <Accordion.Item eventKey='0'>
          <Accordion.Header id='dashboard-filters-accordion'>
            {window.innerWidth > 600
              ? (
                <>
                  <span>Display report data from&ensp;</span>
                  <span style={boldAccordianTitleStyle}>{date.format(startDate, 'DD/MM/YYYY')}</span>
                  &ensp;to&ensp;
                  <span style={boldAccordianTitleStyle}>{date.format(endDate, 'DD/MM/YYYY')}</span>
                  <span>&ensp;split by site</span>
                  {selectedSite.label && selectedSite.label !== TEXT_LOADING
                    ? (
                      <span style={boldAccordianTitleStyle}>
                        {`: "${selectedSite.label}"`}
                      </span>
                      )
                    : null}
                </>
                )
              : (
                <>
                  <span style={boldAccordianTitleStyle}>{date.format(startDate, 'DD/MM/YYYY')}</span>
                  &ensp;-&ensp;
                  <span style={boldAccordianTitleStyle}>{date.format(endDate, 'DD/MM/YYYY')}</span>
                  <br />
                  {selectedSite.label && selectedSite.label !== TEXT_LOADING
                    ? (
                      <span style={boldAccordianTitleStyle}>
                        {`: "${selectedSite.label.length > 23
                          ? selectedSite.label.slice(0, 21) + '...'
                          : selectedSite.label}"`}
                      </span>
                      )
                    : null}
                </>
                )}
          </Accordion.Header>
          <Accordion.Body>
            <>
              <Row>
                <Col
                  md='12'
                  lg='6'
                  style={{ paddingRight: '10px' }}
                >
                  <Row style={{ padding: '0px' }}>
                    <Col
                      style={{ padding: '0px' }}
                      onClick={() => { collapseAccordion(document) }}
                    />
                    <Col
                      xs='auto'
                      style={{ padding: '0px' }}
                    >
                      <span>Site:&ensp;</span>
                      <Select
                        id='site-dropdown'
                        type='select'
                        styles={SELECT_STYLE}
                        isSearchable
                        maxMenuHeight={180}
                        isDisabled={fetchingReports}
                        value={selectedSite}
                        onChange={e => {
                          setSelectedSite(e)
                          setSelectedAdunit({
                            label: reports.filter(r => r.site_id === e.value)[0].item_name,
                            value: reports.filter(r => r.site_id === e.value)[0].item_id
                          })
                        }}
                        options={reports && reports.length ? getSiteOptions() : []}
                      />
                    </Col>
                    {parseInt(window.innerWidth) < 992
                      ? (
                        <Col style={{ padding: '0px' }} />
                        )
                      : null}
                  </Row>
                </Col>
                <Col
                  md='12'
                  lg='6'
                  style={{ paddingLeft: '10px' }}
                >
                  <Row style={{ padding: '0px' }}>
                    {parseInt(window.innerWidth) < 992
                      ? (
                        <Col
                          style={{ padding: '0px' }}
                          onClick={() => { collapseAccordion(document) }}
                        />
                        )
                      : null}
                    <Col
                      xs='auto'
                      style={{ padding: '0px' }}
                    >
                      {window.innerWidth < 600 ? (<br />) : null}
                      <>
                        <span>AdUnit:&ensp;</span>
                        <Select
                          id='adunit-dropdown'
                          type='select'
                          styles={SELECT_STYLE}
                          isSearchable
                          maxMenuHeight={180}
                          isDisabled={fetchingReports}
                          value={selectedAdunit}
                          onChange={e => { setSelectedAdunit(e) }}
                          options={reports && reports.length ? getAdunitOptions() : []}
                        />
                      </>
                    </Col>
                    <Col
                      style={{ padding: '0px' }}
                      onClick={() => { collapseAccordion(document) }}
                    />
                  </Row>
                </Col>
              </Row>
              <br />
              <Row>
                <Col onClick={() => { collapseAccordion(document) }} />
                <Col xs='auto'>
                  {fetchingReports
                    ? (
                      <Row style={{ height: '400px' }}>
                        <Col />
                        <Col xs='auto'>
                          <div style={{ paddingTop: '150px' }}>
                            <ThemedSpinnerComponent />
                          </div>
                        </Col>
                        <Col />
                      </Row>
                      )
                    : (
                      <Row>
                        <Col
                          style={{ padding: '0px' }}
                        />
                        <Col xs='auto'>
                          <DateRangePickerComponent
                            showDefinedDates={parseInt(window.innerWidth) > 815}
                            handleDateRangeSelect={(e) => {
                              setDateSelection(e.selection)
                              setStartDate(e.selection.startDate)
                              Cookies.set('dashboard-startDate', JSON.stringify(e.selection.startDate))
                              setEndDate(e.selection.endDate)
                              Cookies.set('dashboard-endDate', JSON.stringify(e.selection.endDate))
                            }}
                            dateSelection={dateSelection}
                            disabled={fetchingReports}
                            months={parseInt(window.innerWidth) < 1150 ? 1 : 2}
                          />
                        </Col>
                        <Col
                          style={{ padding: '0px' }}
                        />
                      </Row>
                      )}
                </Col>
                <Col onClick={() => { collapseAccordion(document) }} />
              </Row>
            </>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <br />
      <ErrorBoundary
        FallbackComponent={ErrorFallbackComponent}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <MetricsCardRowComponent
          cardsInARow={6}
          fetchingReports={fetchingReports}
          mainMetricTexts={reports && reports.length
            ? [
                parseInt(getTotalInventory()).toLocaleString(),
                parseInt(getTotalImpression()).toLocaleString(),
              `$${millify(getTotalRevenue(), { precision: 2 })}`,
              // `$${millify(getAverageCpm(), { precision: 2 })}`,
              `$${getTotalImpression() && millify(getTotalRevenue() / (getTotalImpression() / 1000))}`,
              `${millify(getAverageFillRate(), { precision: 1 })}%`,
              millify(getTotalCompletion(), { precision: 1 })
              ]
            : []}
          subMetricTextsOne={reports && reports.length
            ? [
              `Totalled over ${getDateRangeLengthInDaysFromSelection()} day${getDateRangeLengthInDaysFromSelection() > 1 ? 's' : ''}`,
              `Totalled over ${getDateRangeLengthInDaysFromSelection()} day${getDateRangeLengthInDaysFromSelection() > 1 ? 's' : ''}`,
              `Totalled over ${getDateRangeLengthInDaysFromSelection()} day${getDateRangeLengthInDaysFromSelection() > 1 ? 's' : ''}`,
              `Averaged over ${getDateRangeLengthInDaysFromSelection()} day${getDateRangeLengthInDaysFromSelection() > 1 ? 's' : ''}`,
              `Averaged over ${getDateRangeLengthInDaysFromSelection()} day${getDateRangeLengthInDaysFromSelection() > 1 ? 's' : ''}`,
              `Totalled over ${getDateRangeLengthInDaysFromSelection()} day${getDateRangeLengthInDaysFromSelection() > 1 ? 's' : ''}`
              ]
            : []}
          subMetricIconsOne={[
            'fa-calendar',
            'fa-calendar',
            'fa-calendar',
            'fa-calendar',
            'fa-calendar',
            'fa-calendar'
          ]}
          subMetricTextsTwo={reports && reports.length
            ? [
                getTotalInventory() ? `${parseInt(parseInt(getTotalInventory()) / (getSelectedReports().length)).toLocaleString()} per day` : '-',
                getTotalImpression() ? `${parseInt(parseInt(getTotalImpression()) / (getSelectedReports().length)).toLocaleString()} per day` : '-',
                getTotalRevenue() ? `$${millify(parseFloat(getTotalRevenue()) / (getSelectedReports().length), { precision: 2 })} per day` : '-',
                getAverageCpm() ? `Between $${millify(getLowestCpm(), { precision: 2 })} and $${millify(getHighestCpm(), { precision: 2 })}` : '-',
                getAverageFillRate() ? `Between ${millify(getLowestFillRate(), { precision: 1 })}% and ${millify(getHighestFillRate(), { precision: 1 })}%` : '-',
                getTotalCompletion() ? `${millify(parseInt(parseInt(getTotalCompletion()) / (getSelectedReports().length)), { precision: 1 })} per day` : '-'
              ]
            : []}
          subMetricIconsTwo={[
            'fa-calculator',
            'fa-calculator',
            'fa-calculator',
            'fa-calculator',
            'fa-calculator',
            'fa-calculator'
          ]}
          chartSeries={[
            [{
              name: METRIC_INVENTORY,
              data: reports && reports.length ? getSelectedReports().map(r => r.inventory) : []
            }],
            [{
              name: METRIC_IMPRESSION,
              data: reports && reports.length ? getSelectedReports().map(r => r.impression) : []
            }],
            [{
              name: METRIC_REVENUE,
              data: reports && reports.length ? getSelectedReports().map(r => r.revenue) : []
            }],
            [{
              name: METRIC_CPM,
              data: reports && reports.length ? getSelectedReports().map(r => r.ecpm) : []
            }],
            [{
              name: METRIC_FILLRATE,
              data: reports && reports.length ? getSelectedReports().map(r => r.fillRate) : []
            }],
            [{
              name: METRIC_COMPLETION,
              data: reports && reports.length ? getSelectedReports().map(r => r.complete) : []
            }]
          ]}
          chartCategories={getSelectedReportCategories()}
          showCharts={reports && reports.length && getSelectedReports().length > 1}
        />
      </ErrorBoundary>
      {reports
        ? (
          <ErrorBoundary
            FallbackComponent={ErrorFallbackComponent}
            onReset={() => {
              // reset the state of your app so the error doesn't happen again
            }}
          >
            <ExportComponent
              fetchingReports={fetchingReports}
              data={reports.map(r => {
                return {
                  site: r.site_name,
                  date: r.date,
                  inventory: r.inventory,
                  impression: r.impression,
                  revenue: `$${millify(r.revenue, { precision: 2 })}`,
                  cpm: `$${millify(r.ecpm, { precision: 2 })}`,
                  fillRate: `${millify(r.fillRate, { precision: 1 })}%`,
                  completed: `${millify(r.complete, { precision: 1 })}`
                }
              })}
              fromDate={date.format(startDate, 'DD-MM-YY')}
              toDate={date.format(endDate, 'DD-MM-YY')}
            />
          </ErrorBoundary>
          )
        : null}
      {reports && reports.length && getSelectedReports().length > 1
        ? (
          <ErrorBoundary
            FallbackComponent={ErrorFallbackComponent}
            onReset={() => {
              // reset the state of your app so the error doesn't happen again
            }}
          >
            <ChartCardRowComponent
              cardsInARow={4}
              onClickExpand={(ref, title) => {
                onClickExpandChart(
                  setChartModalTitle,
                  setChartModalBody,
                  handleShowChartModal,
                  ref,
                  title,
                  getSelectedReportCategories()
                )
              }}
              series={[
                [{
                  name: METRIC_INVENTORY,
                  data: getSelectedReports().map(r => r.inventory)
                },
                {
                  name: METRIC_IMPRESSION,
                  data: getSelectedReports().map(r => r.impression)
                }],
                [{
                  name: METRIC_REVENUE,
                  data: getSelectedReports().map(r => r.revenue)
                }],
                [{
                  name: METRIC_CPM,
                  data: getSelectedReports().map(r => r.ecpm)
                }],
                [{
                  name: METRIC_FILLRATE,
                  data: getSelectedReports().map(r => r.fillRate)
                }]
              ]}
              categories={getSelectedReportCategories()}
            />
          </ErrorBoundary>
          )
        : (
          <CardComponent>
            <div>Charts are not available when only one day is selected. Select multiple days to view charts.</div>
          </CardComponent>
          )}
      {worldMapReports && worldMapReports.length > 0
        ? (
          <ErrorBoundary
            FallbackComponent={ErrorFallbackComponent}
            onReset={() => {
              // reset the state of your app so the error doesn't happen again
            }}
          >
            <WorldMapComponent worldMapReports={worldMapReports} />
          </ErrorBoundary>
          )
        : null}
    </>
  )
}

export default DashboardView
